import React from 'react';
import { Helmet } from 'react-helmet';
import './layout.css';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

const initGA = () => {
  if (typeof window == 'undefined') {
    return null;
  }

  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    window.dataLayer.push(args);
  }
  gtag('js', new Date());
  gtag('config', 'G-MYE8W6FBYT');

  return null;
};

const Layout = ({
  children,
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MYE8W6FBYT"
        ></script>
        <script>{initGA()}</script>
      </Helmet>

      {children}
    </>
  );
};

export default Layout;
